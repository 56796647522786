.notification-container {
	font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999
}

.notification {
	background: #fff;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	padding: 10px;
	color: #000;
}

.notification-title {
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 6px;
    color: #000;
}

.notification-message {
	margin: 0;
	text-align: left;
    /* height: 18px; */
    /* margin-left: -1px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:#000;
}

.notification-image {
    float: left;
    margin-right: 12px;
}

.notification-image img {
    width: 30px;
    height: 30px;
}


@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}